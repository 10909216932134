import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { GenericAnalyticsDTO } from '../../../api/model';
import { Button, Card, Panel, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface GenericAnalyticsProps {
    report: GenericAnalyticsDTO[];
    includeUserInfo?: boolean;
}

const GenericAnalyticsTable = ({ report, includeUserInfo }: GenericAnalyticsProps) => {
    let columns: ColumnDescription[] = [
        {
            dataField: 'created',
            text: 'Created',
            sort: true,
            formatter: (value) => ViewHelper.formatDateTimeFromISO(value),
            csvText: 'created',
            csvFormatter: (value) => value ?? '',
        },
        {
            dataField: 'category',
            text: 'Category',
            csvText: 'category',
            csvFormatter: (value) => value ?? '',
        },
        {
            dataField: 'action',
            text: 'Action',
            csvText: 'action',
            csvFormatter: (value) => value ?? '',
        },
        {
            dataField: 'label',
            text: 'Label',
            csvText: 'label',
            csvFormatter: (value) => value ?? '',
            formatter: (value) => <div style={{ maxWidth: '120px' }}>{value}</div>,
        },
        {
            dataField: 'ipAddress',
            text: 'IP Address',
            csvText: 'ip_address',
            csvFormatter: (value) => value ?? '',
        },
        {
            dataField: 'countryCode',
            text: 'Country Code',
            csvText: 'country_code',
            csvFormatter: (value) => value ?? '',
        },
    ];
    if (includeUserInfo) {
        columns = [
            ...columns,
            {
                dataField: 'email',
                text: 'email',
                csvText: 'email',
                csvFormatter: (value) => value ?? '',
            },
            {
                dataField: 'userName',
                text: 'User Name',
                csvText: 'user_name',
                csvFormatter: (value) => value ?? '',
            },
            {
                dataField: 'loginSource',
                text: 'Login source',
                csvText: 'login_source',
                csvFormatter: (value) => value ?? '',
            },
        ];
    }

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button color="primary" onClick={handleClick}>
                <i className="fa fa-download" />
                &nbsp;Export current page as CSV
            </Button>
        );
    };

    return (
        <React.Fragment>
            <Card>
                <ToolkitProvider
                    keyField="id"
                    data={report}
                    columns={columns}
                    exportCSV={{
                        fileName: 'generic-analytics-export.csv',
                    }}
                >
                    {(props) => (
                        <>
                            <TableWrapper>
                                <Table {...props.baseProps} />
                            </TableWrapper>
                            <Panel>
                                <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                            </Panel>
                        </>
                    )}
                </ToolkitProvider>
            </Card>
        </React.Fragment>
    );
};

export default GenericAnalyticsTable;
