export default class SoarUtil {
    static sleep(ms: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    static attachTimestampToUrl(url: string): string {
        if (url.includes('?')) {
            return `${url}&t=${new Date().getTime()}`;
        }
        return `${url}?t=${new Date().getTime()}`;
    }

    static async checkImageExists(url: string, timeoutInSeconds?: number): Promise<boolean> {
        const startTime = Date.now();
        const timeout = timeoutInSeconds || 30000; // 30 seconds

        while (Date.now() - startTime < timeout) {
            try {
                const response = await fetch(this.attachTimestampToUrl(url), { method: 'HEAD' });
                if (response.ok) {
                    return true; // Image exists
                }
            } catch (error) {
                // Handle error if needed
            }
            await SoarUtil.sleep(1000); // Wait for 1 second before retrying
        }

        return false; // Image does not exist within 15 seconds
    }
}
