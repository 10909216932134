import React from 'react';
import Select from 'react-select';

export interface GenericAnalyticsCategory {
    value: string;
    label: string;
}

export const findGenericAnalyticsCategory = (categories?: string[]): string | undefined => {
    return categories?.find((c) => GenericAnalyticsCategoryOptions.findIndex((pc) => pc.value == c.toLowerCase()) >= 0);
};

export const GenericAnalyticsCategoryOptions: GenericAnalyticsCategory[] = [
    { value: '', label: 'Not Applicable' },
    { value: 'SurveyMapsV1', label: 'SurveyMapsV1' },
    { value: 'sharing', label: 'sharing' },
    { value: 'JoinPage', label: 'JoinPage' },
    { value: 'test', label: 'test' },
];

interface Props {
    value?: string;
    onChange: (temporal: string) => void;
    disabled?: boolean;
}

const mapExisting = (category?: string): GenericAnalyticsCategory => {
    return GenericAnalyticsCategoryOptions.find((c) => c.value === category?.toLowerCase());
};

const defaultValue = GenericAnalyticsCategoryOptions.find(option => option.value === 'SurveyMapsV1');

const GenericAnalyticsCategoryInput = ({ value, onChange, disabled }: Props) => {
    const onValueChange = (selectedCategory) => {
        console.log('selectedCategory: ', selectedCategory);
        onChange(selectedCategory.value);
    };

    return (
        <Select
            disabled={disabled}
            name="category"
            options={GenericAnalyticsCategoryOptions}
            value={mapExisting(value)}
            onChange={onValueChange}
            defaultValue={defaultValue}
        />
    );
};

export default GenericAnalyticsCategoryInput;
