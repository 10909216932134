export type Provider = 'SINERGISE' | 'SKYM50' | 'SOAR' | 'CGSTL' | '21AT';
export type ProductName =
    | 'A100'
    | 'A80'
    | 'A75'
    | 'A50'
    | 'A50EXT'
    | 'AN100'
    | 'NCN100'
    | 'NC80'
    | 'NC75'
    | 'NC50'
    | 'SEN_2_L1C'
    | 'LAND_8_L1C'
    | 'SEN_1_GRD'
    | 'IMAGE'
    | 'MAP'
    | 'ORDER'
    | 'TASK_MAP'
    | 'EMBED_MAP'
    | 'MAP_DRAW'
    | 'MAP_CANVA'
    | 'WMS'
    | 'WMTS'
    | 'COG'
    | 'EXTERNAL_TILE_LAYER';

export enum ReportType {
    DAY = 'DAY',
    MONTH = 'MONTH',
    SATELLITE = 'SATELLITE',
}

export enum ReportInterval {
    DAY = 'DAY',
    MONTH = 'MONTH',
}

export interface DatesFromTo {
    from: Date;
    to: Date;
}

export interface UserReportFilter {
    offset: number;
    limit: number;
    email?: string;
    dates?: DatesFromTo;
}

export interface GenericAnalyticsFilter {
    offset: number;
    limit: number;
    label?: string;
    category?: string;
    action?: string;
    dates?: DatesFromTo;
    includeUserInfo?: boolean;
}
