import React, { useEffect, useRef, useState } from 'react';
import ApiReports from '../../../api/api-reports';
import { GenericAnalyticsSearchDTO } from '../../../api/model';
import { DatesFromTo, GenericAnalyticsFilter } from '../../../api/model-api';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_7_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import GenericAnalyticsActionInput from './generic-analytics-action-input';
import GenericAnalyticsCategoryInput from './generic-analytics-category-input';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from '../../style';
import Pagination from '../pagination';
import GenericAnalyticsTable from './generic-analytics-table';

const GenericAnalytics = () => {
    const [report, setReport] = useState<GenericAnalyticsSearchDTO>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<GenericAnalyticsFilter>({
        offset: 0,
        limit: 100,
        dates: LAST_7_DAYS,
        category: '',
        action: '',
        includeUserInfo: false,
    });
    const messageRef = useRef(null);

    useEffect(() => {
        const refresh = async () => {
            setLoading(true);
            try {
                const res = await ApiReports.getGenericAnalyticsData(filter);
                setReport(res);
                if (messageRef.current) {
                    messageRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                }
            } catch (err) {
                //setError(err.toString());
            } finally {
                setLoading(false);
            }
        };
        refresh();
    }, [filter]);

    const onDatesChange = (dates: DatesFromTo) => {
        setFilter({ ...filter, dates: dates });
    };

    const onActionChange = (action: string) => {
        setFilter({ ...filter, action: action });
    };

    const onCategoryChange = (category: string) => {
        setFilter({ ...filter, category: category });
    };

    const onOffsetChange = (offset: number) => {
        setFilter({ ...filter, offset: offset });
    };

    const onPageSizeChange = (value: number) => {
        setFilter({ ...filter, offset: 0, limit: value });
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange
                                fromTo={filter.dates}
                                onChange={onDatesChange}
                                selectedDate={DEFAULT_DATE_RANGES.LAST_7_DAYS}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="category">Category</Label>
                            <GenericAnalyticsCategoryInput value={filter.category} onChange={onCategoryChange} />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="action">Action</Label>
                            <GenericAnalyticsActionInput value={filter.action} onChange={onActionChange} />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="includeUserInfo">User info</Label>
                            <Input
                                title="User info"
                                value={filter.includeUserInfo ? 'yes' : 'no'}
                                onChange={(e) => setFilter({ ...filter, includeUserInfo: !filter.includeUserInfo })}
                                type="select"
                                name="includeUserInfo"
                            >
                                <option value={'yes'}>Include</option>
                                <option value={'no'}>Exclude</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading ? (
                <Card height="800px">
                    <Spinner text="Loading Analytic Data" />
                </Card>
            ) : (
                <Card>
                    {report && (
                        <>
                            <GenericAnalyticsTable report={report.analytics} includeUserInfo={filter.includeUserInfo} />
                            <Pagination
                                total={report?.total}
                                offset={filter.offset}
                                limit={filter.limit}
                                onOffsetChange={onOffsetChange}
                                onLimitChange={onPageSizeChange}
                            />
                        </>
                    )}
                </Card>
            )}
        </React.Fragment>
    );
};

export default GenericAnalytics;
