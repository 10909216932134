import React from 'react';
import Select from 'react-select';

export interface GenericAnalyticsAction {
    value: string;
    label: string;
}

export const findGenericAnalyticsAction = (categories?: string[]): string | undefined => {
    return categories?.find((c) => GenericAnalyticsActionOptions.findIndex((pc) => pc.value == c.toLowerCase()) >= 0);
};

export const GenericAnalyticsActionOptions: GenericAnalyticsAction[] = [
    { value: '', label: 'Not Applicable' },
    { value: 'email', label: 'email' },
    { value: 'Answer', label: 'Answer' },
    { value: 'User already logged in', label: 'User already logged in' },
    { value: 'Registered', label: 'Registered' },
    { value: 'View', label: 'View' },
    { value: 'test', label: 'test' },
];

interface Props {
    value?: string;
    onChange: (temporal: string) => void;
    disabled?: boolean;
}

const mapExisting = (Action?: string): GenericAnalyticsAction => {
    return GenericAnalyticsActionOptions.find((c) => c.value === Action?.toLowerCase());
};

const GenericAnalyticsActionInput = ({ value, onChange, disabled }: Props) => {
    const onValueChange = (selectedAction) => {
        console.log('selectedAction: ', selectedAction);
        onChange(selectedAction.value);
    };

    return (
        <Select
            disabled={disabled}
            name="Action"
            options={GenericAnalyticsActionOptions}
            value={mapExisting(value)}
            onChange={onValueChange}
        />
    );
};

export default GenericAnalyticsActionInput;
