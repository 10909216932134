import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { UserRole } from './api/model';
import Comments from './components/Comments/comments';
import ContactUs from './components/ContactUs/contact-us';
import Dashboard from './components/Dashboard/dashboard';
import Dmt from './components/Dmt';
import DmtTaskSinglePage from './components/Dmt/dmt-task-single-page';
import Featured from './components/Featured';
import Login from './components/Login';
import SupplierOrder from './components/Orders/Supplier/supplier-order';
import SupplierOrders from './components/Orders/Supplier/supplier-orders';
import OrderSinglePage from './components/Orders/order-single-page';
import Orders from './components/Orders/orders';
import ReportedListings from './components/Reported/reported-listings';
import CommentsReport from './components/Reports/Comments/comments-report';
import Hashtags from './components/Reports/HashTags/hashtags';
import ListingsUploadData from './components/Reports/Listings/listings-upload-data';
import DataLake from './components/Reports/Satellites/data-lake';
import TilingEngineReport from './components/Reports/TilingEngine/tiling-engine-report';
import TrafficReports from './components/Reports/Traffic/traffic-reports';
import UserReport from './components/Reports/Users/user-report';
import ReviewQueue from './components/Reviews/review-queue';
import SingleReview from './components/Reviews/single-review';
import Search from './components/Search';
import CreateSubdomain from './components/Subdomain/create-subdomain';
import NewOrder from './components/Supplier/new-order';
import Suppliers from './components/Supplier/suppliers';
import TMS from './components/ExternalMapServices/TMS/tms';
import TMSLayerPage from './components/ExternalMapServices/TMS/Layer/tms-layer';
import TMSServer from './components/ExternalMapServices/TMS/Server/tms-server';
import Tracker from './components/Tracker/tracker';
import Moderators from './components/Users/ManageModerators/moderators';
import ReputableUsers from './components/Users/ManageReputableUsers/reputable-users';
import ManageUsers from './components/Users/manage-users';
import Vouchers from './components/Vouchers/vouchers';
import WMS from './components/ExternalMapServices/WMS/wms';
import WMSLayer from './components/ExternalMapServices/WMS/Layer/wms-layer';
import WMSServer from './components/ExternalMapServices/WMS/Server/wms-server';
import { Container, Title } from './components/style';
import { selectIsLoggedIn, selectUser } from './store/AppStore/selectors';
import STAC from './components/ExternalMapServices/STAC/stac';
import StacCatalog from './components/ExternalMapServices/STAC/Catalog/stac-catalog';
import StacItem from './components/ExternalMapServices/STAC/Catalog/stac-item';
import Profiles from './components/ExternalMapServices/ManagedProfiles/profiles';
import ProfilePage from './components/ExternalMapServices/ManagedProfiles/Profile/profile-page';
import DrawProjects from './components/Reports/DrawProjects/draw-projects-reports';
import ProfileListingPage from './components/ExternalMapServices/ManagedProfiles/Listings/profile-listing-page';
import GenericAnalyticsNavigation from './components/Reports/GenericAnalyticsReport/generic-analytics-navigation';

const Routes = () => {
    const user = useSelector(selectUser);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    if (isLoggedIn === undefined) {
        return (
            <Switch>
                <Route
                    component={() => {
                        return (
                            <Container>
                                <Title>Loading</Title>
                            </Container>
                        );
                    }}
                />
            </Switch>
        );
    } else if (user === undefined) {
        return (
            <Switch>
                <Route component={Login} />
            </Switch>
        );
    } else if (user.roles?.length === 1 && user.roles.includes(UserRole.ORDERS_SUPPLIER)) {
        return (
            <Switch>
                <Redirect exact path="/provider/task/:id" to="/supplier/orders/:id" />
                <Route exact path="/supplier/orders/:id" component={SupplierOrder} />
                <Route exact path="/supplier/orders" component={SupplierOrders} />
                <Redirect to="/supplier/orders" />
            </Switch>
        );
    } else {
        const reviewQueueDefault = user.roles?.includes(UserRole.SUPER_MAP) && !user.roles?.includes(UserRole.REPORTS);
        const accessSuperMap = user?.roles?.includes(UserRole.SUPER_MAP);
        const accessContactUs = user?.roles?.includes(UserRole.CONTACT_US);
        const accessComments = user?.roles?.includes(UserRole.COMMENTS);
        const accessReports = user?.roles?.includes(UserRole.REPORTS);
        const accessReportsDrawProject = user?.roles?.includes(UserRole.REPORTS_DRAW_PROJECT);
        const accessAnalytics = user?.roles?.includes(UserRole.ANALYTICS);
        const accessGenericAnalytics = user?.roles?.includes(UserRole.ANALYTICS);
        const accessOrders = user?.roles?.includes(UserRole.ORDERS_ADMIN);
        const accessSupplier = user?.roles?.includes(UserRole.ORDERS_SUPPLIER);
        const accessTMS = user?.roles?.includes(UserRole.TMS);
        const accessDmt = user?.roles?.includes(UserRole.DMT_ADMIN);
        const accessVouchers = user?.roles?.includes(UserRole.VOUCHERS);
        const accessUsers = user?.roles?.includes(UserRole.USERS_ADMIN);
        const accessSubdomains = user?.roles.includes(UserRole.SUBDOMAINS);
        const accessModerators = user?.roles.includes(UserRole.USERS_ADMIN);
        const accessReputableUser = user?.roles.includes(UserRole.USERS_ADMIN);
        const accessSTAC = user?.roles.includes(UserRole.STAC);

        const accessTracker = user?.roles.includes(UserRole.TRACKERS);
        return (
            <Switch>
                {user && <Redirect exact path="/login" to="/" />}
                {accessSuperMap && <Route exact path="/" component={reviewQueueDefault ? ReviewQueue : Dashboard} />}

                {accessSuperMap && <Route exact path="/reviews" component={ReviewQueue} />}
                {accessSuperMap && <Route exact path="/review" component={SingleReview} />}
                {accessSuperMap && <Route exact path="/featured" component={Featured} />}
                {accessSuperMap && <Route exact path="/search" component={Search} />}
                {accessSuperMap && <Route exact path="/sponsor" component={ReviewQueue} />}

                {accessSuperMap && <Route exact path="/profiles" component={Profiles} />}
                {accessSuperMap && <Route exact path="/profiles/:id" component={ProfilePage} />}
                {accessSuperMap && (
                    <Route exact path="/profiles/:id/listings/:listingId" component={ProfileListingPage} />
                )}

                {accessTMS && <Route exact path="/tms" component={TMS} />}
                {accessTMS && <Route exact path="/tms/:id" component={TMSServer} />}
                {accessTMS && <Route exact path="/tms/:serverId/:layerId" component={TMSLayerPage} />}

                {accessSuperMap && <Route exact path="/wms" component={WMS} />}
                {accessSuperMap && <Route exact path="/wms/:id" component={WMSServer} />}
                {accessSuperMap && <Route exact path="/wms/:serverId/:layerId" component={WMSLayer} />}
                {accessSuperMap && <Route exact path="/reported-maps" component={ReportedListings} />}

                {accessSTAC && <Route exact path="/stac" component={STAC} />}
                {accessSTAC && <Route exact path="/stac/:id" component={StacCatalog} />}
                {accessSTAC && <Route exact path="/stac/:catalogId/:itemId" component={StacItem} />}

                {accessSubdomains && <Route exact path="/subdomains" component={CreateSubdomain} />}

                {accessReports && <Route exact path="/reports/traffic" component={TrafficReports} />}
                {accessReports && <Route exact path="/reports/users" component={UserReport} />}
                {accessReports && <Route exact path="/reports/uploads" component={ListingsUploadData} />}
                {accessReports && <Route exact path="/reports/comments" component={CommentsReport} />}
                {accessReports && <Route exact path="/reports/tiles" component={TilingEngineReport} />}
                {accessReports && <Route exact path="/reports/hashtags" component={Hashtags} />}
                {accessReportsDrawProject && <Route exact path="/reports/draw-projects" component={DrawProjects} />}
                {accessGenericAnalytics && (
                    <Route exact path="/reports/generic-analytics" component={GenericAnalyticsNavigation} />
                )}

                {accessAnalytics && <Route exact path="/analytics" component={DataLake} />}

                {accessOrders && <Redirect exact path="/tasks" to="/orders" />}
                {accessOrders && <Route exact path="/orders" component={Orders} />}
                {accessOrders && <Redirect exact path="/task/:id" to="/orders/:id" />}
                {accessOrders && <Route exact path="/orders/:id" component={OrderSinglePage} />}

                {accessSupplier && <Route exact path="/supplier/orders/:id" component={SupplierOrder} />}
                {accessSupplier && <Route exact path="/supplier/orders" component={SupplierOrders} />}

                {accessModerators && <Route exact path="/moderators" component={Moderators} />}
                {accessReputableUser && <Route exact path="/reputable-users" component={ReputableUsers} />}

                {accessOrders && <Route exact path="/suppliers" component={Suppliers} />}
                {accessOrders && <Route exact path="/suppliers/new-order" component={NewOrder} />}
                {accessDmt && <Route exact path="/dmt" component={Dmt} />}
                {accessDmt && <Route exact path="/dmt/:id" component={DmtTaskSinglePage} />}

                {accessVouchers && <Route exact path="/vouchers" component={Vouchers} />}

                {accessContactUs && <Route exact path="/contact-us" component={ContactUs} />}

                {accessComments && <Route exact path="/comments" component={Comments} />}

                {accessUsers && <Route exact path="/users" component={ManageUsers} />}

                {accessTracker && <Route exact path="/tracker" component={Tracker} />}

                <Route path="/login" component={Login} />
            </Switch>
        );
    }
};

export default Routes;
