import * as React from 'react';
import { ListingDTO } from '../../api/model';
import ConfirmModal from '../Shared/confirm-modal';
import ListingPreview from '../Shared/listing-preview';
import { Button, Card } from '../style';
import styled from 'styled-components';
import ButtonsPanel from '../Shared/buttons-panel';
import { useDispatch } from 'react-redux';
import { actionUpdateFeatured } from '../../store/AppStore/actions';
import { toast } from 'react-toastify';

interface Props {
    listing: ListingDTO;
    featured: any;
    deleteListing: (listingId: number) => void;
    isAddFeatured?: boolean;
    featuredName?: string;
    featuredType?: string;
    featuredCount?: number;
}

const SearchCard = (props: Props) => {
    const dispatch = useDispatch();

    const addFeatured = (listingId: number, type: string) =>
        dispatch(actionUpdateFeatured(listingId, type, props.listing.featured, true));
    const removeFeatured = (listingId: number, type: string) =>
        dispatch(actionUpdateFeatured(listingId, type, props.listing.featured, false));

    const isFeatured = (listing: ListingDTO): boolean => {
        return props.featured.find((l) => l.id === listing.id) !== undefined;
    };

    const isRejected = props.listing.review == 'REJECTED';
    const isPending = props.listing.review == 'PENDING';

    const handleAddToFeatured = () => {
        if (props.featuredCount.toString() <= props.featured?.length.toString()) {
            toast.error(`You already have ${props.featuredCount} maps added, Please remove some before adding more!`);
            return;
        }
        addFeatured(props.listing.id, props.featuredType);
    };

    return (
        <SearchCardContainer key={'features_' + props.listing.id} isAddFeatured={props.isAddFeatured}>
            <ListingPreview listing={props.listing} fullRes={true} />
            <ButtonControls>
                {!isRejected && !isPending && props.featured && props.isAddFeatured && (
                    <>
                        {!isFeatured(props.listing) && (
                            <Button onClick={handleAddToFeatured}>
                                {`Add to ${props.featuredName}` || 'Add to featured'}
                            </Button>
                        )}
                        {isFeatured(props.listing) && (
                            <Button
                                color="warning"
                                onClick={() => removeFeatured(props.listing.id, props.featuredType)}
                            >
                                {`Remove from ${props.featuredName ? props.featuredName : 'Featured'}`}
                            </Button>
                        )}
                    </>
                )}
                {props.listing.review !== 'REJECTED' && (
                    <ConfirmModal
                        title={`Remove from Soar`}
                        message={`Are you sure you want remove selected listing from Soar? It will change review to REJECTED.`}
                        onConfirm={() => props.deleteListing(props.listing.id)}
                        color={'danger'}
                    />
                )}
            </ButtonControls>
        </SearchCardContainer>
    );
};

export default SearchCard;

const ButtonControls = styled(ButtonsPanel)`
    text-align: center;
`;

interface CardProps {
    isAddFeatured?: boolean;
}

const SearchCardContainer = styled(Card)<CardProps>`
    border: ${(props) => (props.isAddFeatured ? '1px solid rgba(125, 125, 125, 0.3)' : '')};
    background: ${(props) => (props.isAddFeatured ? 'rgb(23 26 29)' : '')};
`;
