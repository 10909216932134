import React, { useEffect, useState, useCallback } from 'react';
import ApiUser from '../../api/api-user';
import { UserForAdminDTO, UserRole } from '../../api/model';
import InputUserRole from '../Shared/Filters/input-user-role';
import { Card, Col, Container, FormGroup, Input, Label, Row, Spinner, Subtitle, Title, Button } from '../style';
import ManageUsersTable from './manage-users-table';

const DEBOUNCE_DELAY = 300;
const PAGE_LIMIT = 50;

const ManageUsers = () => {
    const [data, setData] = useState<UserForAdminDTO[]>([]);
    const [userSearch, setUserSearch] = useState<string>('');
    const [role, setRole] = useState<UserRole>(UserRole.ALL_USERS);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState(0);

    const fetchUsers = useCallback(async (search: string, role?: UserRole, offset = 0) => {
        setLoading(true);
        try {
            const res = await ApiUser.getUsers(
                offset,
                PAGE_LIMIT,
                role === UserRole.ALL_USERS ? undefined : role,
                search || undefined
            );
            setHasMore(res.length === PAGE_LIMIT);
            if (offset === 0) {
                setData(res);
            } else {
                setData((prevData) => [...prevData, ...res]);
            }
        } catch (err) {
            alert(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (role !== UserRole.ALL_USERS || userSearch.length >= 2) {
                setOffset(0);
                fetchUsers(userSearch, role, 0);
            } else {
                setData([]);
                setHasMore(false);
            }
        }, DEBOUNCE_DELAY);

        return () => clearTimeout(handler);
    }, [userSearch, role, fetchUsers]);

    const loadMore = () => {
        const newOffset = offset + PAGE_LIMIT;
        setOffset(newOffset);
        fetchUsers(userSearch, role, newOffset);
    };

    const onUpdated = (user: UserForAdminDTO) => {
        setData(data.map((u) => (u.id === user.id ? user : u)));
    };

    const onDeleted = (userId: string) => {
        setData(data.filter((u) => u.id !== userId));
    };

    return (
        <Container>
            <Title>Manage Users</Title>
            <Card>
                <Row>
                    <Col md={8}>
                        <FormGroup>
                            <Label for="user-search">Search by Email</Label>
                            <Input
                                title="Enter email"
                                placeholder="Enter at least 2 characters to search"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value?.toLocaleLowerCase())}
                                name="user-search"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="user-role">Filter by Role</Label>
                            <InputUserRole value={role} onChange={setRole} />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading && offset === 0 ? (
                <Card height="104px">
                    <Spinner text="Loading Users" />
                </Card>
            ) : data.length > 0 ? (
                <React.Fragment>
                    <Card>
                        <Subtitle>Table of Users</Subtitle>
                        <ManageUsersTable items={data} onUpdated={onUpdated} onDeleted={onDeleted} />
                        {hasMore && (
                            <Button onClick={loadMore} disabled={loading}>
                                {loading ? 'Loading...' : 'Load More'}
                            </Button>
                        )}
                    </Card>
                </React.Fragment>
            ) : userSearch.length >= 2 ? (
                <Card>
                    <Subtitle>No Users Found</Subtitle>
                </Card>
            ) : (
                <Card>
                    <Subtitle>Enter at least 2 characters to begin searching.</Subtitle>
                </Card>
            )}
        </Container>
    );
};

export default ManageUsers;
