import React from 'react';
import { useSelector } from 'react-redux';
import { selectSoarReport } from '../../../store/ReportsStore/selectors';
import { Card, Col, Container, Row, Spinner, Statistic, Subtitle, Title } from '../../style';
import ViewHelper from '../../view-helper';
import UserBarChart from './user-bar-chart';

const UserReport = () => {
    const soarReport = useSelector(selectSoarReport);

    if (!soarReport)
        return (
            <Container>
                <Title>User Report</Title>
                <Card height="290px">
                    <Spinner text="Loading User Report" />
                </Card>
                <Card height="132px">
                    <Spinner text="Loading User Report" />
                </Card>
                <Card height="600px">
                    <Spinner text="Loading User Report" />
                </Card>
            </Container>
        );

    const { soar, usersByDay, usersByMonth } = soarReport;
    const usersThisMonth = usersByMonth.intervals[usersByMonth.intervals.length - 1].total;
    const usersLast30Days = usersByDay.intervals.slice(-30).reduce((acc, current) => acc + current.total, 0);
    return (
        <Container>
            <Title>User Report</Title>
            <Row>
                <Col xs={12} md={3}>
                    <Card title="Total number of registered Users">
                        <Subtitle>Registered Users</Subtitle>
                        <Statistic>{usersByDay.total.toLocaleString()}</Statistic>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card title="Total new users in the current month">
                        <Subtitle>New Users Current Month</Subtitle>
                        <Statistic>{usersThisMonth.toLocaleString()}</Statistic>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                   <Card title="Total new users in last 30 days">
                       <Subtitle>New Users last 30 days</Subtitle>
                       <Statistic>{usersLast30Days.toLocaleString()}</Statistic>
                   </Card>
               </Col>

                <Col xs={12} md={3}>
                    <Card title="Total number of approved maps">
                        <Subtitle>Maps Approved</Subtitle>
                        <Statistic>{`${soar.maps.toLocaleString()} (${ViewHelper.formatBytes(
                            soar.mapsSize
                        )})`}</Statistic>
                        {/* <SubtitleHint>from {userWithApprovedMap} unique users</SubtitleHint> */}
                    </Card>
                </Col> 
            </Row>
            <UserBarChart />
            {/* <UserData /> */}
        </Container>
    );
};

export default UserReport;
