import React from 'react';
import { UserRole } from '../../../api/model';
import { FormGroup, Input } from '../../style';

interface Props {
    onChange: (interval?: UserRole) => void;
    value?: UserRole;
}

const InputUserRole = (props: Props) => {
    return (
        <FormGroup>
            <Input
                title="User Role"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value as any)}
                type="select"
            >
                <option value={UserRole.ALL_USERS}>All Users</option>
                <option value={UserRole.COMMENTS}>Comments</option>
                <option value={UserRole.WMS}>WMS</option>
                <option value={UserRole.TMS}>TMS</option>
                <option value={UserRole.CONTACT_US}>Enquiries</option>
                <option value={UserRole.REPORTS}>Reports</option>
                <option value={UserRole.SUBDOMAINS}>Subdomains</option>
                <option value={UserRole.SUPER_MAP}>Super map</option>
                <option value={UserRole.ORDERS_ADMIN}>Satellite tasks</option>
                <option value={UserRole.ORDERS_SUPPLIER}>Satellite tasks (provider)</option>
                <option value={UserRole.TILE_ADMIN}>Tiling engine (specified requests)</option>
                <option value={UserRole.USERS_ADMIN}>Users management</option>
                <option value={UserRole.VOUCHERS}>Vouchers</option>
            </Input>
        </FormGroup>
    );
};

export default InputUserRole;
