import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import ApiWms, { BodyUpdateWMSLayer, WMSLayer as WMSLayerDTO } from '../../../../api/api-wms';
import TagInputField from '../../../Shared/tag-input-field';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    Multiselect,
    Row,
    Spinner,
    Subtitle,
    Title,
} from '../../../style';

import { toast } from 'react-toastify';
import { OssUploader } from '../../../../api/oss-uploader';
import SoarUtil from '../../../../lib/soar-util';
import CategoriesInput, { filterPrimaryCategories } from '../../../Shared/categories-input-field';
import TemporalInput, { findTemporalCategory } from '../../../Shared/temporal-input-field';
import MapServicesLayerDeleteLegend from '../../MapServicesSharedUI/Layer/map-services-layer-delete-legend';
import MapServicesLayerEditLegend from '../../MapServicesSharedUI/Layer/map-services-layer-edit-legend';
import MapServicesLayerPreviewImage from '../../MapServicesSharedUI/Layer/map-services-layer-preview-image';
import MapServicesLayerPreviewMap from '../../MapServicesSharedUI/Layer/map-services-layer-preview-map';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';
import WMSLayerSelectExistingLegend from './wms-layer-select-existing-legend';
import WMSLayerVisibility from './wms-layer-visibility';

interface MatchParams {
    serverId: string;
    layerId: string;
}

interface WMSLayerProps extends RouteComponentProps<MatchParams> {
    wmsLayer: WMSLayerDTO;
}

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 200;

const WMSLayer = (props: WMSLayerProps) => {
    const serverId = Number(props.match.params.serverId);
    const layerId = Number(props.match.params.layerId);

    const [wmsLayer, setWmsLayer] = useState<WMSLayerDTO>();
    const [wmsLayerError, setWmsLayerError] = useState<Error | undefined>();
    const [isUpdatingWMSLayer, setIsUpdatingWMSLayer] = useState(false);
    const [isUploadingPreview, setIsUploadingPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [tags, setTags] = useState<string[]>([]);
    const [legendUrl, setLegendUrl] = useState<string>('');
    const [serverLegendUrl, setServerLegendUrl] = useState<string | undefined>(undefined);
    const [legendCustomUrl, setLegendCustomUrl] = useState<string | undefined>(undefined);
    const [isLoadingLegend, setIsLoadingLegend] = useState<boolean>(false);
    const [temporalCategory, setTemporalCategory] = useState<string | undefined>(undefined);
    const [primaryCategories, setPrimaryCategories] = useState<string[]>([]);
    const [adminNotes, setAdminNotes] = useState<string | undefined>(undefined);
    const [base64Preview, setBase64Preview] = useState<string | undefined>(undefined);
    const [boundingBox, setBoundingBox] = useState<string>('');
    const [srs, setSrs] = useState<string>('');

    const getWMSLayer = useCallback(() => {
        setIsLoading(true);
        ApiWms.getWMSLayers(serverId)
            .then((wmsServer) => {
                const layer = wmsServer.find((t) => t.id === layerId);
                if (!layer) {
                    setWmsLayerError({
                        name: 'layer not found',
                        message: 'The layer does not exist or no longer exists.',
                    } as Error);
                    return;
                }

                setWmsLayer(layer);
                setTitle(layer.titleOvr || layer.title);
                setDescription(layer.abstractOvr || layer.abstract);
                setTags(layer.keywordsOvr || layer.keywords);
                setLegendUrl(layer.legendUrl);
                setLegendCustomUrl(layer.legendUrlCustom || undefined);
                setServerLegendUrl(layer.legendUrls[0] || undefined);
                setAdminNotes(layer.adminNotes);
                setBoundingBox(layer.boundingBoxWKTOvr || layer.boundingBoxWKT);
                setSrs(layer.srsOvr ?? layer.srs);

                setPrimaryCategories(filterPrimaryCategories(layer.categories));
                setTemporalCategory(findTemporalCategory(layer.categories));
            })
            .catch((err) => {
                setWmsLayerError(err);
            })
            .finally(() => {
                setIsLoading(false);
                setIsLoadingLegend(false);
            });
    }, [serverId, layerId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setWmsLayer(undefined);
        setTitle(undefined);
        setDescription(undefined);
        setTags(undefined);
        setBase64Preview(undefined);
        getWMSLayer();
    }, [serverId, layerId, getWMSLayer]);

    useEffect(() => {
        if (wmsLayer) {
            if (srs && wmsLayer.srsOvr !== srs) {
                setWmsLayer({ ...wmsLayer, srsOvr: srs });
            }
        }
    }, [srs, wmsLayer]);

    const handleUpdateWMSLayerDetails = () => {
        if (title.length > MAX_TITLE_LENGTH) {
            toast.error(`Please review the title, maximum characters ${MAX_TITLE_LENGTH}`);
            return;
        }

        if (description?.length && description.split(' ').length > MAX_DESCRIPTION_LENGTH) {
            toast.error(`Please review the description, maximum word count ${MAX_DESCRIPTION_LENGTH}`);
            return;
        }

        if (primaryCategories && (primaryCategories.length === 0 || primaryCategories.length > 3)) {
            toast.error('Please select between 1 - 3 categories');
            return;
        }

        const geometryWkt = Wellknown.parse(boundingBox);
        if (!geometryWkt) {
            toast.error('Invalid format for bounding box');
            return;
        }

        setIsUpdatingWMSLayer(true);
        const selectedCategories = primaryCategories || [];
        if (temporalCategory) {
            selectedCategories.push(temporalCategory);
        }

        const body: BodyUpdateWMSLayer = {
            categories: selectedCategories,
            title: title,
            abstract: description,
            keywords: tags,
            legendUrl: legendUrl,
            boundingBoxWKT: boundingBox,
            adminNotes: adminNotes,
            srs: srs,
        };

        ApiWms.updateWMSLayerDetails(serverId, layerId, body)
            .then((_) => {
                getWMSLayer();
            })
            .catch((err) => {
                setWmsLayerError(err);
            })
            .finally(() => {
                alert('Layer details updated');
                setIsUpdatingWMSLayer(false);
            });
    };

    const handleGeneratePreview = async (base64Preview: string) => {
        setBase64Preview(base64Preview);
        setIsUploadingPreview(true);
        try {
            // Convert file from base54 encoded string to a File object
            const filePromise = await fetch(base64Preview);
            const fileBlob = await filePromise.blob();
            const filename = `${serverId}-${layerId}-${Date.now().toString()}.png`;
            const file = new File([fileBlob], filename, { type: 'image/png' });

            // Upload file to OSS bucket
            const uploadCredentials = await ApiWms.getWMSLayerPreviewUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(uploadCredentials);
            await uploader.multipartUpload(file, file.name, (_) => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
            setBase64Preview(URL.createObjectURL(file));
        } catch (err) {
            setWmsLayerError(err);
        } finally {
            setIsUploadingPreview(false);
        }
    };

    const handleValidateFileType = (file: File) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleSelectPreviewImage = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        try {
            const credentials = await ApiWms.getWMSLayerPreviewUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(credentials);
            await uploader.multipartUpload(file, file.name, () => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
            setBase64Preview(URL.createObjectURL(file));
        } catch (err) {
            setWmsLayerError(err);
        }
    };

    const handleAddLegend = async (updatedUrl: string) => {
        setIsLoadingLegend(true);

        ApiWms.addWMSLayerLegend(serverId, layerId, updatedUrl)
            .then(() => {
                setLegendUrl(updatedUrl);
            })
            .finally(() => {
                setIsLoadingLegend(false);
            });
    };

    const handleUploadLegend = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        setIsLoadingLegend(true);
        try {
            const credentials = await ApiWms.getWMSLegendUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(credentials);
            const filename = `${serverId}-${layerId}-${Date.now().toString()}-${file.name}`;
            const ossKey = await uploader.uploadFileToStorage(file, filename);
            if (ossKey) {
                const legendCustomUrl = `https://short-preview.soar.earth/${ossKey}`;
                const exist = await SoarUtil.checkImageExists(legendCustomUrl);
                if (exist) {
                    handleAddLegend(legendCustomUrl);
                }
            }
        } catch (err) {
            setWmsLayerError(err);
        } finally {
            setIsLoadingLegend(false);
        }
    };

    const handleDeleteLegend = () => {
        setIsLoadingLegend(true);
        ApiWms.deleteWMSLayerLegend(serverId, layerId)
            .then((_) => {
                setLegendUrl(undefined);
            })
            .catch((err) => {
                alert('An error occurred while deleting the legend: ' + err.message);
            })
            .finally(() => {
                setIsLoadingLegend(false);
            });
    };

    if (wmsLayerError) {
        return (
            <Container>
                <Title>Manage {wmsLayer?.standard ?? 'WMS'} Layer</Title>
                <Row>
                    <Col md={12}>
                        <Card>
                            <ErrorMessage>{wmsLayerError.message}</ErrorMessage>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!wmsLayer) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    return (
        <Container>
            <Title>
                Manage {wmsLayer.standard} Layer ({wmsLayer.name}){' '}
            </Title>

            <Row>
                <Col md={12}>
                    <Card>
                        <WMSLayerVisibility wmsLayer={wmsLayer} onWMSLayerVisibilityUpdated={() => getWMSLayer()} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <Card>
                        <Subtitle>{wmsLayer.standard} Layer Details</Subtitle>

                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="title">Description</Label>
                            <Input
                                type="textarea"
                                rows="3"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="temporal-category">Temporal Category (Optional)</Label>
                            <TemporalInput value={temporalCategory} onChange={setTemporalCategory} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="category">Primary Category</Label>
                            <CategoriesInput
                                values={primaryCategories}
                                onChange={(selectedCategories) => {
                                    setPrimaryCategories(selectedCategories);
                                }}
                                limit={3}
                            />
                        </FormGroup>

                        <FormGroup>
                            <LegendLabel for="legend">
                                Legend
                                <span>
                                    <LegendControls>
                                        {legendUrl ? (
                                            <LegendItem>
                                                <MapServicesLayerDeleteLegend
                                                    handleConfirmDeleteLegend={() => handleDeleteLegend()}
                                                />
                                                <LineDivider>|</LineDivider>
                                            </LegendItem>
                                        ) : null}
                                        {!legendUrl && (serverLegendUrl || legendCustomUrl) ? (
                                            <React.Fragment>
                                                <WMSLayerSelectExistingLegend
                                                    serverLegend={serverLegendUrl || undefined}
                                                    legendCustom={legendCustomUrl || undefined}
                                                    handleAddLegend={handleAddLegend}
                                                />
                                                <LineDivider>|</LineDivider>
                                            </React.Fragment>
                                        ) : null}
                                        <MapServicesLayerEditLegend
                                            onSelectUploadLegend={(file) => handleUploadLegend(file)}
                                            legendUrl={legendUrl}
                                        />
                                    </LegendControls>
                                </span>
                            </LegendLabel>
                            {legendUrl && !isLoadingLegend ? <Legend src={legendUrl} /> : null}
                            {isLoadingLegend ? <Spinner /> : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="tags">Keywords (to help with search)</Label>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label for="adminNotes">Admin Notes</Label>
                            <Input
                                type="textarea"
                                rows="3"
                                name="adminNotes"
                                value={adminNotes}
                                onChange={(e) => setAdminNotes(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="preview">
                                {`Preview image ${
                                    base64Preview || wmsLayer.previewUrl ? ': Click preview to change' : ''
                                }`}
                            </Label>
                            <MapServicesLayerPreviewImage
                                previewUrl={wmsLayer.previewUrl}
                                isUploadingPreview={isUploadingPreview}
                                base64Preview={base64Preview}
                                onSelectPreviewImage={(file) => handleSelectPreviewImage(file)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="boundingBox">Bounding Box WKT</Label>
                            <Input
                                type="text"
                                name="boundingBox"
                                value={boundingBox}
                                onChange={(e) => setBoundingBox(e.target.value)}
                            />
                        </FormGroup>

                        {wmsLayer.srsOptions && (
                            <FormGroup>
                                <Label for="boundingBox">SRS Options</Label>
                                <Multiselect
                                    name="srs"
                                    options={wmsLayer.srsOptions.map((srsOption) => ({
                                        value: srsOption,
                                        label: srsOption,
                                    }))}
                                    defaultValue={{ value: srs, label: srs }}
                                    onChange={(selectedSrs) => {
                                        setSrs(selectedSrs.value);
                                    }}
                                    isSearchable={false}
                                />
                            </FormGroup>
                        )}

                        <FormGroup>
                            <ButtonGroup>
                                <Button disabled={isUpdatingWMSLayer} onClick={() => handleUpdateWMSLayerDetails()}>
                                    {isUpdatingWMSLayer ? <Spinner /> : null}
                                    Save changes
                                </Button>
                            </ButtonGroup>
                        </FormGroup>
                    </Card>
                </Col>

                <Col md={7}>
                    <Card>
                        <MapServicesLayerPreviewMap
                            key={`${wmsLayer.id}-${wmsLayer.srsOvr}`}
                            layer={wmsLayer}
                            onGeneratePreview={(base64: string) => {
                                handleGeneratePreview(base64);
                            }}
                            onUsePreviewBoundingBox={(boundingBox: string) => setBoundingBox(boundingBox)}
                            isWMS
                        />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Card>
                        <MapServicesDetailsTable title="Raw Layer metadata" data={wmsLayer} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default WMSLayer;

const LegendLabel = styled(Label)`
    width: 100%;
    margin-bottom: 10px;

    span {
        float: right;
    }
`;

const Legend = styled.img`
    display: block;
    max-width: 100%;
`;

const LegendItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const LineDivider = styled.div`
    margin: 0px 8px;
    margin-top: -3px;
    font-size: 20px;
    height: 20px;
`;

const LegendControls = styled.div`
    display: flex;
`;
