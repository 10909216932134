import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import ApiWms, { WMSServer } from '../../../../api/api-wms';
import store from '../../../../store/store';
import { Button, Col, FormGroup, Input, Label, Row, Subtitle } from '../../../style';
import ViewHelper from '../../../view-helper';
import WMSDeleteAction from './wms-delete-action';
import NotificationUtil from '../../../../lib/notification-util';

interface WMSServerEditDetailsProps {
    wms: WMSServer;
    onSaveWMSDetails: (url: string, title: string, license: string, adminNotes: string) => void;
}


const WMSServerEditDetails = (props: WMSServerEditDetailsProps) => {
    const [wmsAdminNotes, setWmsAdminNotes] = useState<string>('');
    const [license, setLicense] = useState<string>('');
    const [wmsTitle, setWmsTitle] = useState<string>('');
    const [wmsUrl, setWmsUrl] = useState<string>('');
    const [skipProxy, setSkipProxy] = useState<boolean>(false);

    useEffect(() => {
        setWmsTitle(props.wms.titleOvr || props.wms.title || '');
        setWmsAdminNotes(props.wms.adminNotes || '');
        setLicense(props.wms.license || '');
        setSkipProxy(props.wms.skipProxy || false);
        setWmsUrl(props.wms.url);
    }, [props.wms]);

    const handleSkipProxy = () => {
        try {
            if(skipProxy) {
            ApiWms.updateSkipProxy(props.wms.id, false);
            NotificationUtil.showSuccessMessage('Proxy enabled successfully!!');
            } else {
            ApiWms.updateSkipProxy(props.wms.id, true);
            NotificationUtil.showSuccessMessage('Proxy skipped successfully!!');
            }
            setSkipProxy(!skipProxy);
            store.dispatch(push(`/wms/${props.wms.id}`));
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
        }
    }

    const handleRefreshServer = () => {
        ApiWms.refreshWMSServer(props.wms.id)
            .then((_) => {
                NotificationUtil.showSuccessMessage('WMS server has been refreshed');
            })
            .catch((err) => {
                NotificationUtil.showErrorNotificationApi(err);
            });
    }

    return (
        <React.Fragment>
            <Subtitle>Server details</Subtitle>
            <React.Fragment>
                <FormGroup>
                    <Label for="url">WMS server url</Label>
                    <Input type="text" value={wmsUrl} onChange={(e) => setWmsUrl(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label for="title">Server Title - only for internal usage</Label>
                    <Input
                        type="text"
                        value={wmsTitle}
                        placeholder="Title for the WMS Server"
                        onChange={(e) => setWmsTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="adminNotes">Admin Notes</Label>
                    <Input
                        type="textarea"
                        rows={3}
                        value={wmsAdminNotes}
                        onChange={(e) => setWmsAdminNotes(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="license">License (default license for public layers)</Label>
                    <Input
                        value={license}
                        onChange={(e) => {
                            setLicense(e.target.value);
                        }}
                        type="select"
                        name="license"
                    >
                        <option key="">Choose license</option>
                        {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for="skipProxy">Skip Proxy</Label>
                    <Input disabled type="text" value={skipProxy} />
                </FormGroup>

                <Row>
                    <Col>
                        <Button onClick={() => store.dispatch(push(`/profiles/${props.wms.userId}`))}>
                            Back to profile
                        </Button>
                        <Button
                            onClick={() => {
                                props.onSaveWMSDetails(wmsUrl,wmsTitle, license, wmsAdminNotes);
                            }}
                        >
                            Save Changes
                        </Button>
                        <Button onClick={handleSkipProxy}>
                            {skipProxy ? 'Use Proxy' : 'Skip Proxy'}
                        </Button>
                        <Button onClick={handleRefreshServer}>
                            Refresh Server
                        </Button>
                        <WMSDeleteAction wms={props.wms} invalidateServer={() => store.dispatch(push('/wms'))}>
                            <Button color="danger">Delete Server</Button>
                        </WMSDeleteAction>
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    );
};

export default WMSServerEditDetails;
