import React, { useEffect, useRef, useState } from 'react';
import Pagination from '../pagination';
import ApiReports from '../../../api/api-reports';
import { GenericAnalyticsSearchDTO } from '../../../api/model';
import { DatesFromTo, GenericAnalyticsFilter } from '../../../api/model-api';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_7_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, FormGroup, Label, Row, Spinner } from '../../style';
import GenericAnalyticsTable from './generic-analytics-table';

const GenericAnalyticsJoinPage = () => {
    const [report, setReport] = useState<GenericAnalyticsSearchDTO>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<GenericAnalyticsFilter>({
        offset: 0,
        limit: 100,
        dates: LAST_7_DAYS,
        category: 'JoinPage',
        action: 'Registered',
        includeUserInfo: true,
    });
    const messageRef = useRef(null);

    useEffect(() => {
        const refresh = async () => {
            setLoading(true);
            try {
                const res = await ApiReports.getGenericAnalyticsData(filter);
                setReport(res);
                if (messageRef.current) {
                    messageRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                }
            } catch (err) {
                // Handle error
            } finally {
                setLoading(false);
            }
        };
        refresh();
    }, [filter]);

    const onDatesChange = (dates: DatesFromTo) => {
        setFilter({ ...filter, dates: dates });
    };

    const onOffsetChange = (offset: number) => {
        setFilter({ ...filter, offset: offset });
    };

    const onPageSizeChange = (value: number) => {
        setFilter({ ...filter, offset: 0, limit: value });
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange
                                fromTo={filter.dates}
                                onChange={onDatesChange}
                                selectedDate={DEFAULT_DATE_RANGES.LAST_7_DAYS}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading ? (
                <Card height="800px">
                    <Spinner text="Loading Analytic Data" />
                </Card>
            ) : (
                <Card>
                    {report && (
                        <>
                            <GenericAnalyticsTable report={report.analytics} includeUserInfo={filter.includeUserInfo} />
                            <Pagination
                                total={report?.total}
                                offset={filter.offset}
                                limit={filter.limit}
                                onOffsetChange={onOffsetChange}
                                onLimitChange={onPageSizeChange}
                            />
                        </>
                    )}
                </Card>
            )}
        </React.Fragment>
    );
};

export default GenericAnalyticsJoinPage;
