import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, FormGroup, Input, SubtitleHint } from '../style';

interface DataPaginationProps {
    total: number;
    offset: number;
    limit: number;
    onOffsetChange: (page: number) => void;
    onLimitChange: (limit: number) => void;
}

const Pagination = (props: DataPaginationProps) => {
    const { total, offset, limit } = props;

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [page, setPage] = useState(0);
    useEffect(() => {
        setNumberOfPages(Math.ceil(total / limit));
    }, [total, limit]);

    useEffect(() => {
        setPage(offset / limit + 1);
    }, [offset, limit]);

    const onClickPrevious = () => {
        props.onOffsetChange(offset - limit);
    };

    const onClickNext = () => {
        props.onOffsetChange(offset + limit);
    };

    const onLastClick = () => {
        props.onOffsetChange((numberOfPages - 1) * limit);
    };

    const onPageChange = (value: number) => {
        let newPage;
        if (value < 0) {
            newPage = 0;
        } else if (value >= numberOfPages) {
            newPage = numberOfPages - 1;
        } else {
            newPage = value;
        }
        setPage(newPage);
    };

    const onPageBlur = () => {
        props.onOffsetChange((page - 1) * limit);
    };

    const onPageSizeChange = (value: number) => {
        props.onLimitChange(Number(value));
    };

    return (
        <PaginationContainer>
            <PaginationButton onClick={() => props.onOffsetChange(0)} disabled={page === 1}>
                <i className="fa fa-chevron-left" />
                <i className="fa fa-chevron-left" />
                &nbsp;First
            </PaginationButton>
            <PaginationButton onClick={onClickPrevious} disabled={page === 1}>
                <i className="fa fa-chevron-left" />
                &nbsp;Prev
            </PaginationButton>

            <PaginationFormGroup>
                <Input
                    title="Report Type"
                    value={page}
                    onChange={(e) => onPageChange(e.target.value as any)}
                    onBlur={onPageBlur}
                    type="number"
                    name="page"
                />
            </PaginationFormGroup>
            <PaginationSubtitleHint>of {numberOfPages} pages.</PaginationSubtitleHint>

            <PaginationFormGroup>
                <Input
                    title="Report Type"
                    value={limit}
                    onChange={(e) => onPageSizeChange(e.target.value as any as number)}
                    type="select"
                    name="pageSize"
                >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value={2500}>2500</option>
                    <option value={5000}>5000</option>
                </Input>
            </PaginationFormGroup>
            <PaginationSubtitleHint>per page</PaginationSubtitleHint>

            <PaginationButton onClick={onClickNext} disabled={page >= numberOfPages}>
                <i className="fa fa-chevron-right" />
                &nbsp;Next
            </PaginationButton>
            <PaginationButton onClick={onLastClick} disabled={page >= numberOfPages}>
                <i className="fa fa-chevron-right" />
                <i className="fa fa-chevron-right" />
                &nbsp;Last
            </PaginationButton>
        </PaginationContainer>
    );
};

export default Pagination;

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const PaginationFormGroup = styled(FormGroup)`
    margin: 5px;
    margin-bottom: 0px;
`;

const PaginationButton = styled(Button)`
    margin: 5px;
`;

const PaginationSubtitleHint = styled(SubtitleHint)`
    margin-top: 4px;
`;
