import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Container, Title } from '../../style';
import GenericAnalytics from './generic-analytics';
import GenericAnalyticsJoinPage from './generic-analytics-join-page';

export enum DashboardType {
    Data,
    JoinPage,
}

const GenericAnalyticsNavigation = () => {
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.Data);

    return (
        <Container>
            <Title>Generic Analytics</Title>
            <Card>
                <ButtonContainer>
                    <Button active={dashboard === DashboardType.Data} onClick={() => setDashboard(DashboardType.Data)}>
                        Data
                    </Button>
                    <Button
                        active={dashboard === DashboardType.JoinPage}
                        onClick={() => setDashboard(DashboardType.JoinPage)}
                    >
                        JoinPage
                    </Button>
                </ButtonContainer>
            </Card>
            <React.Fragment>
                {dashboard === DashboardType.Data ? <GenericAnalytics /> : null}
                {dashboard === DashboardType.JoinPage ? <GenericAnalyticsJoinPage /> : null}
            </React.Fragment>
        </Container>
    );
};

export default GenericAnalyticsNavigation;

const ButtonContainer = styled.div`
    text-align: center;
    margin: 8px;

    button {
        margin: 8px;
    }
`;
